import { html, Component } from 'htm/preact';
import * as Tracking from '../tracking';
import ContactLink from './ContactLink';
import ContributeLink from './ContributeLink';
import {
  StatsIcon,
  AboutIcon,
  HamburgerIcon,
  ContributeIcon,
  ContactIcon,
  AudioOffIcon,
  AudioOnIcon,
} from './icons';

import {
  hasTodaysGameBeenPlayed,
  CURRENT_GAME_CONTEXT,
  CURRENT_GAME_CONTEXT_ID,
  ALL_ACTIVE_GAME_CONTEXTS,
} from '../utils/game';

import Audio from '../interfaces/audio';
import { GlobalEventBus } from '../utils/events';

export default class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuVisible: false,
      audioMuted: Audio.getMuted(),
    };
    this.openMenuBound = this.openMenu.bind(this);
    this.closeMenuBound = this.closeMenu.bind(this);

    Audio.on('mutedChange', muted => this.setState({ audioMuted: muted }));
  }

  click(e) {
    if (this.state.menuVisible) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
    e.stopPropagation();
  }

  openMenu() {
    if (this.props.onOpen) {
      this.props.onOpen();
    }
    this.setState({ menuVisible: true });
  }
  closeMenu() {
    this.setState({ menuVisible: false });
  }

  componentDidMount() {
    GlobalEventBus.on('openMainMenu', this.openMenuBound);
    window.document.body.addEventListener('click', this.closeMenuBound);
  }

  componentWillUnmount() {
    GlobalEventBus.off('openMainMenu', this.openMenuBound);
    window.document.body.removeEventListener('click', this.closeMenuBound);
  }

  render({ openAbout, openStats }, { menuVisible, audioMuted }) {
    return html`
      <button class="header__button header__button--has-menu" onclick=${e => this.click(e)}>
        <${HamburgerIcon} />
      </button>
      <nav
        class="header__main-menu ${menuVisible ? 'header__main-menu--visible' : ''}"
        onclick=${e => e.stopPropagation()}
      >
        ${Object.values(ALL_ACTIVE_GAME_CONTEXTS)
          .sort((a, b) => a.menuPriority - b.menuPriority)
          .map(gameContext => {
            const alreadyPlayed = hasTodaysGameBeenPlayed(gameContext);
            return html`
              <a class="header__main-menu__link" href="${gameContext.route}">
                <span>${gameContext.menuName}</span>
                ${gameContext.menuBadge
                  ? html`
                      <div class="badge badge--small">${gameContext.menuBadge}</div>
                    `
                  : html`
                      <span />
                    `}
                <span>${alreadyPlayed ? '✅' : ' '}</span>
              </a>
            `;
          })}

        <div class="header__main-menu__spacer" />

        <a class="header__main-menu__link header__main-menu__link--full-width"
          href="${`/archive/${CURRENT_GAME_CONTEXT_ID}/random`}"
          onClick=${() => Tracking.randomArchiveLink()}
        >
          <span>${CURRENT_GAME_CONTEXT.randomGameCta}</span>
        </a>

        <div class="header__main-menu__spacer" />

        <div class="header__main-menu__grid">
          <button title="About" class="header__button" onClick=${openAbout}>
            <${AboutIcon} />
          </button>
          <${ContributeLink} class="header__button">
            <${ContributeIcon} />
          </ContributeLink>
          <${ContactLink} class="header__button">
            <${ContactIcon} />
          </ContactLink>
          <button title="Stats" class="header__button" onClick=${openStats}>
            <${StatsIcon} />
          </button>
          <button title="Sound" class="header__button" onClick=${() => Audio.setMuted(!audioMuted)}>
            ${
              audioMuted
                ? html`
                    <${AudioOffIcon} />
                  `
                : html`
                    <${AudioOnIcon} />
                  `
            }
          </button>
        </div>
      </nav>
    `;
  }
}
