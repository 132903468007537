import { html, Component } from 'htm/preact';

import Modal from './Modal';

const NewGameReady = () => html`
  <h2 class="listed-title">🏡 New Game 🏡</h2>

  <p class="modal--help__headline">There's a new game of Listed available!</p>
`;

export default class NewGameReadyModal extends Component {
  render({ show }) {
    return html`
      <${Modal}
        show=${show}
        key="modal--new-game-ready"
        variation=${Modal.VARIATION_NOT_DISMISSABLE}
        onConfirm=${() => window.location.reload()}
        confirmText="Play Now"
        extraClassString="modal--new-game-ready"
      >
        <${NewGameReady} />
      </Modal>
    `;
  }
}
