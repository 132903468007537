import { html, Component } from 'htm/preact';

import { formatCurrency, formatDate, formatNumber } from '../utils/intl';
import { CURRENT_GAME_CONTEXT } from '../utils/game';

import ImageCarousel from './ImageCarousel';

import * as Tracking from '../tracking';

const PROPERTY_TYPES = {
  single_family: 'Single Family Home',
  condo: 'Condo',
  ranch: 'Ranch',
  townhouse: 'Townhouse',
  co_op: 'Co-op',
  multi_family: 'Multi-Family',
  land: 'Land',
  manufactured_home: 'Manufactured Home',
};
const ONE_ACRE = 43560;
const ACRE_DISPLAY_THRESHOLD = ONE_ACRE * 0.2;

const showNumUnits = (numUnits, propertyType) =>
  Boolean(numUnits) &&
  (propertyType === 'condo' ||
    propertyType === 'townhouse' ||
    propertyType === 'co_op' ||
    propertyType === 'multi_family');

const displayString = (lookup, value) => {
  if (value in lookup) {
    return lookup[value];
  }

  return value;
};

const ListingAttribute = ({
  shouldShow,
  prop,
  label,
  value,
  placeholderLength = 4,
  skipReveal = false,
  onClickLabel = () => {},
}) => {
  const reveal = shouldShow(prop);
  const placeholder = new Array(placeholderLength + 1).join('x');

  return html`
    <div class="listing__attribute ${reveal && !skipReveal ? 'listing__attribute--reveal' : ''}">
      ${reveal &&
      html`
        <div class="listing__attribute__value">${value}</div>
      `}
      ${!reveal &&
      html`
        <div class="listing__attribute__value--hidden">${placeholder}</div>
      `}
      <div onClick=${e => onClickLabel(e)} class="listing__attribute__label">${label}</div>
    </div>
  `;
};

export default class Listing extends Component {
  constructor(props) {
    super(props);
    this.state = { location: 1 };
  }

  incrementLocation() {
    this.setState({ location: Math.min(this.state.location + 1, 3) });
  }

  componentDidMount() {
    if (this.props.data.imageUrls) {
      this.props.data.imageUrls.forEach(imageUrl => {
        const image = new Image();
        image.src = imageUrl;
      });
    }
  }

  render({ id, data, showCount = 0, showEverything }, { location }) {
    const {
      // address,
      city,
      state,
      // zip,
      url,
      imageUrl,
      imageUrls,
      saleDate,
      // salePrice,
      listDate,
      listPrice,
      beds,
      baths,
      sqft,
      lotSize,
      yearBuilt,
      propertyType,
      numUnits,
      prevSaleDate,
      prevSalePrice,
      specificLocation,

      revealableAttributes,
    } = data;

    const shouldShow = key => showEverything || showCount > revealableAttributes.indexOf(key) + 1;

    let locationDisplay;
    switch (CURRENT_GAME_CONTEXT.locationType) {
      case 'cityState':
        locationDisplay = `${city}, ${state}`;
        break;
      case 'specificLocation':
        locationDisplay = specificLocation;
        break;
    }

    const lotSizeDisplay =
      lotSize > ACRE_DISPLAY_THRESHOLD
        ? `${formatNumber(lotSize / ONE_ACRE)} acres`
        : `${formatNumber(lotSize)} sqft`;

    return html`
      <div class="listing">
        <div class="listing__hero">
          ${imageUrls && imageUrls.length > 1
            ? html`
                <${ImageCarousel}
                  imageUrls=${imageUrls}
                  showCount=${showCount}
                  showEverything=${showEverything}
                />
              `
            : html`
                <img class="listing__img" src="${imageUrl}" />
              `}
          <div class="listing__sale_date">
            Sold on${' '}
            <span>${formatDate(saleDate)}</span>
          </div>
          ${showEverything &&
          html`
            <a
              class="listing__listing_link"
              href="${url}"
              target="_blank"
              onclick=${() => Tracking.clickListing({ id })}
            >
              See the listing »
            </a>
          `}
        </div>

        <div class="listing__attribute__row">
          <${ListingAttribute}
            shouldShow=${shouldShow}
            prop="cityState"
            label="${Array(location).fill('Location').join(', ')}"
            value="${locationDisplay}"
            placeholderLength=${8}
            skipReveal=${showEverything}
            onClickLabel=${() => this.incrementLocation()}
          />

          <div class="listing__attribute__group">
            <${ListingAttribute}
              shouldShow=${shouldShow}
              prop="propertyType"
              label="Property Type"
              value=${displayString(PROPERTY_TYPES, propertyType)}
              placeholderLength=${6}
              skipReveal=${showEverything}
            />

            ${showNumUnits(numUnits, propertyType) &&
            shouldShow('propertyType') &&
            html`
              <${ListingAttribute}
                shouldShow=${() => true}
                prop="propertyType"
                label="Num Units"
                value=${numUnits}
                skipReveal=${showEverything}
              />
            `}
          </div>
        </div>

        <div class="listing__attribute__row">
          <div class="listing__attribute__group">
            <${ListingAttribute}
              shouldShow=${shouldShow}
              prop="bedsBaths"
              label="Beds"
              value=${beds}
              placeholderLength=${2}
              skipReveal=${showEverything}
            />

            <${ListingAttribute}
              shouldShow=${shouldShow}
              prop="bedsBaths"
              label="Baths"
              value=${baths}
              placeholderLength=${2}
              skipReveal=${showEverything}
            />

            <${ListingAttribute}
              shouldShow=${shouldShow}
              prop="yearBuilt"
              label="Built"
              value=${yearBuilt}
              placeholderLength=${3}
              skipReveal=${showEverything}
            />
          </div>

          <div class="listing__attribute__group">
            <${ListingAttribute}
              shouldShow=${shouldShow}
              prop="sqft"
              label="Sqft"
              value=${formatNumber(sqft)}
              placeholderLength=${3}
              skipReveal=${showEverything}
            />

            ${lotSize &&
            html`
              <${ListingAttribute}
                shouldShow=${shouldShow}
                prop="lotSize"
                label="Lot Size"
                value=${lotSizeDisplay}
                placeholderLength=${3}
                skipReveal=${showEverything}
              />
            `}
          </div>
        </div>

        ${((prevSaleDate && prevSalePrice) || (listDate && listPrice)) &&
        html`
          <div class="listing__attribute__row">
            ${prevSaleDate &&
            prevSalePrice &&
            html`
              <div class="listing__attribute__group">
                <${ListingAttribute}
                  shouldShow=${shouldShow}
                  prop="prevSale"
                  label="Prev Sale"
                  value=${formatDate(prevSaleDate)}
                  placeholderLength=${8}
                  skipReveal=${showEverything}
                />

                <${ListingAttribute}
                  shouldShow=${shouldShow}
                  prop="prevSale"
                  label="Prev Price"
                  value=${formatCurrency(prevSalePrice)}
                  placeholderLength=${8}
                  skipReveal=${showEverything}
                />
              </div>
            `}
            ${listDate &&
            listPrice &&
            html`
              <!-- TODO switch to days on market? -->
              <div class="listing__attribute__group">
                <${ListingAttribute}
                  shouldShow=${shouldShow}
                  prop="listPrice"
                  label="List Date"
                  value=${formatDate(listDate)}
                  placeholderLength=${8}
                  skipReveal=${showEverything}
                />

                <${ListingAttribute}
                  shouldShow=${shouldShow}
                  prop="listPrice"
                  label="List Price"
                  value=${formatCurrency(listPrice)}
                  placeholderLength=${8}
                  skipReveal=${showEverything}
                />
              </div>
            `}
          </div>
        `}
      </div>
    `;
  }
}
