import store from '../store';

import * as LocalStorage from '../localStorage';
import { CURRENT_GAME_CONTEXT } from '../utils/game';

const INITIAL_STREAK = { current: 0, max: 0 };

const generateInitialStreak = () => Object.assign({}, INITIAL_STREAK);
const generateInitialUserStats = () => ({
  games: [],
  histogram: {},
  playStreak: generateInitialStreak(),
  winStreak: generateInitialStreak(),
});

/* Pass in the current streak */
const continuedStreak = ({ current, max }) => ({
  current: current + 1,
  max: Math.max(current + 1, max),
});
const stoppedStreak = ({ max }) => ({ current: 0, max });
const newStreak = ({ max }) => ({ current: 1, max: Math.max(max, 1) });

// this gets called (a) when you finish a game, or (b) when you load a new game from the server
// and never finished yesterday's data
export const updateUserStats = store.action(
  (
    {
      userStats: {
        games = [],
        histogram = {},
        playStreak = generateInitialStreak(),
        winStreak = generateInitialStreak(),
      } = generateInitialUserStats(),
    },
    { serverState: { salePrice }, clientState: { id, gameOver, guessed, guessHistory } }
  ) => {
    const lastGame = games[games.length - 1];
    const thisGameConsecutive = lastGame && lastGame.id + 1 === id;

    let newGames = games.map(g => Object.assign({}, g));
    let newHistogram = Object.assign({}, histogram);
    let newPlayStreak = Object.assign({}, playStreak);
    let newWinStreak = Object.assign({}, winStreak);

    /* Update game history */
    if (gameOver) {
      newGames.push({ id, gameOver, guessed, guessHistory, salePrice });
    }

    /* Update win streak */
    if (thisGameConsecutive && guessed) {
      newWinStreak = continuedStreak(winStreak);
    } else if (guessed) {
      newWinStreak = newStreak(winStreak);
    } else {
      newWinStreak = stoppedStreak(winStreak);
    }

    /* Update play streak */
    if (thisGameConsecutive) {
      newPlayStreak = continuedStreak(playStreak);
    } else {
      newPlayStreak = newStreak(playStreak);
    }

    /* Update histogram */
    if (gameOver && guessed) {
      const numGuesses = guessHistory.length;
      newHistogram[numGuesses] = (newHistogram[numGuesses] || 0) + 1;
    } else if (gameOver && !guessed) {
      newHistogram.x = (newHistogram.x || 0) + 1;
    }

    const newUserStats = {
      games: newGames,
      histogram: newHistogram,
      playStreak: newPlayStreak,
      winStreak: newWinStreak,
    };

    return { userStats: newUserStats };
  }
);

export const loadUserStats = store.action(async ({ userStats = generateInitialUserStats() }) => {
  const newUserStats = LocalStorage.getUserStats(CURRENT_GAME_CONTEXT) || userStats;

  return { userStats: newUserStats };
});

/* Keep userStats synced to localstorage */
store.subscribe(newState => {
  LocalStorage.saveUserStats(CURRENT_GAME_CONTEXT, newState.userStats);
});
