import { html, Component } from 'htm/preact';

import * as Tracking from '../tracking';

import ContactLink from './ContactLink';
import ContributeLink from './ContributeLink';
import Modal from './Modal';

const About = () => html`
  <h2 class="listed-title">🏡 About Listed 🏡</h2>

  <p>
    Listed is a passion project built and designed by${' '}
    <a href="https://potch.me" onclick=${() => Tracking.clickBio('potch')} target="_blank">Potch</a>
    ${' '}and${' '}
    <a
      href="https://andrew.pariser.com"
      onclick=${() => Tracking.clickBio('pariser')}
      target="_blank"
    >
      Andrew Pariser
    </a>
    , two friends who make things. Occasionally.
  </p>
  <p>
    To support future entertaining projects like this:
    <br />
    <${ContributeLink}>
      💰 Contribute to offset our server costs.
    </ContributeLink>
  </p>

  <p>
    If you'd like to reach out, we'd love to hear from you.
    <br />
    <${ContactLink}>
      📣 Contact us with feedback, suggestions, or observations.
    </ContactLink>
  </p>
  <p>
    Follow us: ${' '}
    <a
      href="https://www.facebook.com/listed.fun"
      onclick=${() => Tracking.clickSocial('facebook')}
      target="_blank"
    >
      Facebook
    </a>
    ${' '}·${' '}
    <a
      href="https://instagram.com/listed_fun"
      onclick=${() => Tracking.clickSocial('instagram')}
      target="_blank"
    >
      Instagram
    </a>
    ${' '}·${' '}
    <a
      href="https://twitter.com/listed_fun"
      onclick=${() => Tracking.clickSocial('twitter')}
      target="_blank"
    >
      Twitter
    </a>
  </p>
  <p>
    <a href="/privacy-policy.html">Privacy Policy</a>
  </p>
`;

export default class AboutModal extends Component {
  render({ show, onClose }) {
    return html`
      <${Modal}
        show=${show}
        key="modal--about"
        onClose=${onClose}
        closeText="Thanks!"
        extraClassString="modal--about"
      >
        <${About} />
      </Modal>
    `;
  }
}
