import { render, html } from 'htm/preact';
import * as Tracking from './tracking';

window.addEventListener('error', errorEvent => {
  Tracking.error(errorEvent);
});

import App from './App';

let initQueryParams = {};
try {
  const urlSearchParams = new URLSearchParams(window.location.search);
  for (const [key, value] of urlSearchParams) {
    initQueryParams[key] = value;
  }
} catch (e) {
  console.warn('Could not extract initial query params');
}

render(
  html`
    <${App} initQueryParams=${initQueryParams} />
  `,
  document.body
);
