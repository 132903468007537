import { html, Component } from 'htm/preact';
import { connect } from 'unistore/full/preact.es.js';

/* types: 'success', 'error', 'info' */
class ToastStack extends Component {
  render({ toasts = [] }) {
    /* transient toasts go first followed by persistent toasts */
    return html`
      <div class="toast-stack">
        ${toasts
          .filter(toast => !toast.persistent)
          .map(
            ({ type, message }) => html`
              <i class="toast ${type}">${message}</i>
            `
          )}
        ${toasts
          .filter(toast => toast.persistent)
          .map(
            ({ type, message }) => html`
              <i class="toast ${type}">${message}</i>
            `
          )}
      </div>
    `;
  }
}

export default connect(['toasts'])(ToastStack);
