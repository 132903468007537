import { html, Component } from 'htm/preact';

import { connect } from 'unistore/full/preact.es.js';

import Modal from './Modal';

/*

  CURRENT       LONGEST
  STREAK        STREAK

  CURRENT       LONGEST
  STREAK        STREAK

    HISTOGRAM OF WINS

    CALENDAR OF WINS?

*/

const X_LABEL = '❌';

const Stats = ({
  userStats: { playStreak = {}, winStreak = {}, histogram = {}, games = [] } = {},
}) => {
  const gamesPlayed = games.map(g => g.gameOver).length;

  const maxGuesses = 9;
  const guessNumbers = Array.from(Array(maxGuesses).keys()).map(i => i + 1);

  const values = guessNumbers
    .map(guessNumber => histogram[guessNumber] || 0)
    .concat(histogram.x || 0);
  const maxValue = values.reduce((memo, x) => Math.max(memo, x), 1);

  const heights = values
    .map(value => value / maxValue)
    .map(valuePct => (valuePct === '0' ? '1px' : `${valuePct * 100}%`));
  const labels = guessNumbers.concat(X_LABEL);

  const data = heights.map((height, idx) => [height, values[idx], labels[idx]]);

  return html`
    <h2 class="listed-title">🏡 Listed Stats 🏡</h2>

    <div class="stats__streaks-row">
      <div class="stats__streak-item">
        <div class="stats__streak-value">${winStreak.current || 0}</div>
        <div class="stats__streak-name">Win Streak</div>
      </div>
      <div class="stats__streak-item">
        <div class="stats__streak-value">${winStreak.max || 0}</div>
        <div class="stats__streak-name">Longest Streak</div>
      </div>
    </div>
    <div class="stats__streaks-row">
      <div class="stats__streak-item">
        <div class="stats__streak-value">${playStreak.current || 0}</div>
        <div class="stats__streak-name">Play Streak</div>
      </div>
      <div class="stats__streak-item">
        <div class="stats__streak-value">${playStreak.max || 0}</div>
        <div class="stats__streak-name">Longest Play Streak</div>
      </div>
    </div>

    <div class="stats__histogram">
      ${
        /* columns */
        data.map(
          ([height, value, label]) =>
            html`
              <div
                class=${`stats__histogram-column ${
                  label === X_LABEL ? 'stats__histogram-column--failed' : ''
                }`}
                style=${`height: ${height}`}
              >
                <span class="stats__histogram-column__value">${value === 0 ? '' : value}</span>
              </div>
            `
        )
      }
      ${
        /* column labels */
        data.map(
          // eslint-disable-next-line no-unused-vars
          ([_height, _value, label]) =>
            html`
              <div class="stats__histogram-column-label">${label}</div>
            `
        )
      }
    </div>

    <div>${gamesPlayed} ${gamesPlayed === 1 ? 'Game' : 'Games'} Played</div>
  `;
};

const connectedStats = connect('userStats')(Stats);

export default class StatsModal extends Component {
  render({ show, onClose }) {
    return html`
      <${Modal}
        show=${show}
        key="modal--Stats"
        onClose=${onClose}
        closeText="Done"
        extraClassString="modal--stats"
      >
        <${connectedStats} />
      </Modal>
    `;
  }
}
