import { html, Component } from 'htm/preact';
import { createRef } from 'preact';

import * as Tracking from '../tracking';

import ShareButton from './ShareButton';
import { generateGuessEmoji, getMillisUntilNextGame } from '../utils/game';
import { formatCurrency } from '../utils/intl';

const generateGuessString = (guessList, answer) =>
  guessList.map(guess => generateGuessEmoji(guess, answer)).join('');

export default class GameOver extends Component {
  constructor(props) {
    super(props);
    this.nextGameCountdownRef = createRef();
  }

  componentWillUnmount() {
    this.disableCountdown();
  }

  componentDidMount() {
    this.runCountdown();
  }
  componentDidUpdate() {
    this.runCountdown();
  }

  runCountdown() {
    if (this.nextGameCountdownRef && this.nextGameCountdownRef.current) {
      this.updateCountdownValue();
      if (!this.countdownInterval) {
        this.countdownInterval = setInterval(() => this.updateCountdownValue(), 40);
      }
    } else {
      this.disableCountdown();
    }
  }

  disableCountdown() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  updateCountdownValue() {
    if (this.nextGameCountdownRef && this.nextGameCountdownRef.current) {
      const countdown = getMillisUntilNextGame();
      const countdownHours = Math.floor(countdown / (1000 * 60 * 60));
      const countdownMinutes = Math.floor(countdown / (1000 * 60) - countdownHours * 60);
      const countdownSeconds = Math.floor(
        countdown / 1000 - countdownHours * 60 * 60 - countdownMinutes * 60
      );

      this.nextGameCountdownRef.current.textContent = [
        `${countdownHours}`,
        `${countdownMinutes}`.padStart(2, '0'),
        `${countdownSeconds}`.padStart(2, '0'),
      ].join(':');
    }
  }

  render({ clientState, serverState, showGuessHistory }) {
    const { gameOver, guessed, guessHistory, id } = clientState;
    const { salePrice } = serverState.listing;

    let gameOverMessage;
    if (guessed && guessHistory.length === 1) {
      gameOverMessage = 'Phenomenal!';
    } else if (guessed && guessHistory.length < 5) {
      gameOverMessage = 'Impressive!';
    } else if (guessed) {
      gameOverMessage = 'Persistent!';
    }

    const guessString =
      generateGuessString(guessHistory, salePrice) + ((gameOver && !guessed && '❌') || '');

    const guessesPlural = guessHistory.length === 1 ? '1 guess' : `${guessHistory.length} guesses`;

    return html`
      <div class="game-over">
        <div class="game-over__reveal">
          Sold for:
          <b>${' ' + formatCurrency(salePrice)}.${' '}</b>
        </div>
        <div class="game-over__results">
          ${guessed
            ? html`
                ${gameOverMessage}${' '} You got Listed in${' '}
                <a class="game-over__guess-number" onclick=${showGuessHistory}>${guessesPlural}</a>
                .
              `
            : html`
                <a class="game-over__guess-number" onclick=${showGuessHistory}>Out of Guesses</a>
                ${' '}— try again tomorrow!
              `}
        </div>

        <div class="game-over__actions">
          <${ShareButton}
            id=${id}
            guessString=${guessString}
            guessHistory=${guessHistory}
            guessed=${guessed}
          />
          <a
            class="listed-button listed-button--support-us"
            href="https://ko-fi.com/listed"
            target="_blank"
            onClick=${() => Tracking.clickKofi()}
          >
            Support Us
          </a>
        </div>
      </div>
      <div class="game-over__footer">
        <div>
          Next Listed in${' '}
          <span ref=${this.nextGameCountdownRef}></span>
        </div>
      </div>
    `;
  }
}
