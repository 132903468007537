import { v4 as uuidv4 } from 'uuid';

import * as LocalStorage from './localStorage.js';

let userId = null;

export const getUserId = () => {
  if (userId) {
    return userId;
  }

  userId = LocalStorage.getUserId();
  if (userId) {
    return userId;
  }

  userId = uuidv4();
  LocalStorage.setUserId(userId);

  return userId;
};
