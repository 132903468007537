import { html, Component } from 'htm/preact';

import Modal from './Modal';

const Help = () => html`
  <h2 class="listed-title">🏡 Welcome to Listed 🏡</h2>

  <p class="modal--help__headline">
    In this game, your goal is to guess the sale price of a recently sold property
  </p>

  <section class="modal__section">
    <p>After each guess, you learn more about the property, and get feedback about your guess:</p>
    <div class="modal--help__legend">
      <span class="modal--help__emoji">⬆️</span>
      <span>Guess much higher next time</span>

      <span class="modal--help__emoji">↗️</span>
      <span>Guess a little higher next time</span>

      <span class="modal--help__emoji">🏡</span>
      <span>You win!</span>

      <span class="modal--help__emoji">↘️</span>
      <span>Guess a little lower next time</span>

      <span class="modal--help__emoji">⬇️</span>
      <span>Guess much lower next time</span>
    </div>
  </section>

  <section class="modal__section">
    <p>↗️ or ↘️? You're within 10% or $10,000!</p>
    <p>Get within 1% or $5,000 to win</p>
  </section>
`;

export default class HelpModal extends Component {
  render({ show, onClose }) {
    return html`
      <${Modal}
        show=${show}
        key="modal--help"
        onClose=${onClose}
        closeText="Play"
        extraClassString="modal--help"
      >
        <${Help} />
      </Modal>
    `;
  }
}
