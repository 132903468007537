import { html, Component } from 'htm/preact';
import { addToast } from '../actions/toasts.js';

import { CURRENT_GAME_CONTEXT, CURRENT_GAME_CONTEXT_ID } from '../utils/game.js';

import * as Tracking from '../tracking.js';

const isMobileBrowser = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const isFirefox = () => /Firefox|FxiOS/i.test(navigator.userAgent);

export default class ShareButton extends Component {
  constructor(props) {
    super(props);
  }

  async share(e) {
    if (e.button !== 0) {
      // left click only!
      return;
    }

    const { id, guessString, guessHistory, guessed } = this.props;

    const guesses = guessHistory.length === 1 ? '1 guess' : `${guessHistory.length} guesses`;
    const headline = guessed
      ? `I got #${CURRENT_GAME_CONTEXT.socialText} game ${id} in ${guesses}:`
      : `I was stumped by #${CURRENT_GAME_CONTEXT.socialText} game ${id}:`;
    const text = [
      headline,
      guessString,
      `https://listed.fun/${CURRENT_GAME_CONTEXT_ID}/${id}`,
    ].join('\n\n');

    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share#browser_compatibility
    // This is inspired by similar games' browser compatibility handling
    const canNavigatorShare = Boolean(navigator.share) && isMobileBrowser() && !isFirefox();

    // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText#browser_compatibility
    const canNavigatorClipboardWriteText =
      Boolean(navigator.clipboard) && Boolean(navigator.clipboard.writeText);

    let shared = null;
    let shareMessage = null;

    if (canNavigatorShare) {
      try {
        await navigator.share({ text });
        shared = 'navigator-share';
        shareMessage = 'Shared';
      } catch (e) {
        if (DOMException && e instanceof DOMException && e.name === 'AbortError') {
          Tracking.shareAbort({ id });
          return;
        }
        console.error(`Share button navigator.share error: ${e}`);
      }
    }

    if (!shared && canNavigatorClipboardWriteText) {
      try {
        await navigator.clipboard.writeText(text);
        shared = 'clipboard';
        shareMessage = 'Copied to clipboard';
      } catch (e) {
        console.error(`Share button navigator.clipboard.writeText error: ${e}`);
      }
    }

    if (shared) {
      Tracking.share({ id, method: shared });
    } else {
      Tracking.shareFail({ id });
      console.error('No browser support for navigator.share or navigator.clipboard.writeText');
      console.info(
        'Share error debugging:',
        `canNavigatorShare: ${canNavigatorShare}`,
        `canNavigatorClipboardWriteText: ${canNavigatorClipboardWriteText}`,
        `isMobileBrowser(): ${isMobileBrowser()}`,
        `isFirefox(): ${isFirefox()}`,
        `navigator.userAgent: ${navigator.userAgent}`,
        `navigator.share: ${navigator.share}`,
        `navigator.clipboard: ${navigator.clipboard}`
      );
      shareMessage = 'Could not share';
    }

    addToast({
      type: shared ? 'success' : 'error',
      message: shareMessage,
      timeout: 2500,
    });

    return true;
  }

  render({ id, guessString }) {
    if (!id || !guessString) {
      return '';
    }

    return html`
      <button class="listed-button listed-button--share" onpointerup=${e => this.share(e)}>
        Share
      </button>
    `;
  }
}
