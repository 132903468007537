import { html } from 'htm/preact';

import * as Tracking from '../tracking';

export default ({ children, ...other } = {}) => html`
  <a
    title="Contribute"
    href="https://ko-fi.com/listed"
    target="_blank"
    ...${other}
    onClick=${() => Tracking.clickKofi()}
  >
    ${children}
  </a>
`;
