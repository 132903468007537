import { html, Component } from 'htm/preact';
import { createRef } from 'preact';

import { LeftArrow, RightArrow } from './icons';

export default class ImageCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeImageIndex: this.imageCount(props) - 1,
    };

    this.carouselRef = createRef();
  }

  imageCount(props) {
    const p = props || this.props;
    if (p.showEverything) {
      return p.imageUrls.length;
    } else {
      return Math.min(p.showCount, p.imageUrls.length);
    }
  }

  componentDidMount() {
    this.showImageAtIndex(this.state.activeImageIndex);

    const carousel = this.carouselRef.current;
    if (carousel) {
      let currentInView = Math.round(
        (carousel.scrollLeft / carousel.scrollWidth) * carousel.children.length
      );
      carousel.addEventListener('scroll', () => {
        const inView = Math.round(
          (carousel.scrollLeft / carousel.scrollWidth) * carousel.children.length
        );

        if (inView !== currentInView) {
          currentInView = inView;
          this.setState({ activeImageIndex: currentInView });
        }
      });
    }
  }

  componentDidUpdate(previousProps) {
    // probably don't scroll all the way to the end if showEverything is now on but wasn't before
    const nextImageCount = this.imageCount();
    if (nextImageCount > this.imageCount(previousProps)) {
      this.showImageAtIndex(nextImageCount - 1);
    }
  }

  showImageAtIndex(n) {
    if (!this.carouselRef.current) {
      return;
    }

    const carousel = this.carouselRef.current;
    if (carousel.children[n]) {
      carousel.children[n].scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  prevImage(e) {
    e.preventDefault();
    if (!this.canPrevImage()) {
      return;
    }
    this.showImageAtIndex(this.state.activeImageIndex - 1);
  }

  nextImage(e) {
    e.preventDefault();
    if (!this.canNextImage()) {
      return;
    }
    this.showImageAtIndex(this.state.activeImageIndex + 1);
  }

  canPrevImage() {
    return this.state.activeImageIndex > 0;
  }
  canNextImage() {
    return this.state.activeImageIndex < this.imageCount() - 1;
  }

  render({ imageUrls, showEverything }, { activeImageIndex }) {
    const imageCount = this.imageCount();
    const canPrevImage = this.canPrevImage();
    const canNextImage = this.canNextImage();

    return html`
      <div class="listing__img_carousel" ref=${this.carouselRef}>
        ${imageUrls.map((imageUrl, i) => {
          // only show when you've made enough guesses (get this working)
          if (!showEverything && i + 1 > imageCount) {
            return;
          }
          return html`
            <div class="listing__img_carousel__img_container">
              <img class="listing__img_carousel__img" src=${imageUrl} />
            </div>
          `;
        })}
      </div>
      ${(showEverything || imageCount > 1) &&
      html`
        <div class="listing__img_carousel__dot_container">
          ${imageUrls.map((_, i) => {
            if (!showEverything && i + 1 > imageCount) {
              return;
            }

            return html`
              <a
                class="listing__img_carousel__dot ${activeImageIndex === i
                  ? 'listing__img_carousel__dot--active'
                  : ''}"
                onClick=${() => this.showImageAtIndex(i)}
              >
                ${i + 1}
              </a>
            `;
          })}
        </div>

        <a
          class="listing__img_carousel__arrow listing__img_carousel__arrow--prev ${canPrevImage
            ? ''
            : 'listing__img_carousel__arrow--disabled'}"
          onClick=${e => this.prevImage(e)}
        >
          <${LeftArrow} />
        </a>
        <a
          class="listing__img_carousel__arrow listing__img_carousel__arrow--next ${canNextImage
            ? ''
            : 'listing__img_carousel__arrow--disabled'}"
          onClick=${e => this.nextImage(e)}
        >
          <${RightArrow} />
        </a>
      `}
    `;
  }
}
