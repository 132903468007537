// passing undefined says we should use the current user's locale

const dateFormatter = new Intl.DateTimeFormat(undefined);

export const formatDate = date => {
  if (!date) {
    return '';
  }

  if (date instanceof Date) {
    return dateFormatter.format(date);
  }

  return dateFormatter.format(Date.parse(date));
};

let currencyFormatter;
let currency;

export const setCurrency = newCurrency => {
  if (newCurrency && newCurrency !== currency) {
    currency = newCurrency;
    currencyFormatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0, // round to the nearest whole number
      maximumFractionDigits: 0, // round to the nearest whole number
    });
  }
};
setCurrency('USD');

export const formatCurrency = val => currencyFormatter.format(val);

export const pacificTimeFormatter = new Intl.DateTimeFormat('en-US', {
  weekday: 'short',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  timeZone: 'America/Los_Angeles',
  timeZoneName: 'short',
});
export const pacificTimeOffsetHours = date =>
  pacificTimeFormatter.format(date || new Date()).endsWith('PDT') ? -7 : -8;

const numberFormatter = new Intl.NumberFormat(undefined, {
  style: 'decimal',
  maximumFractionDigits: 2,
});

export const formatNumber = number => numberFormatter.format(number);
