import store from '../store';

let incrementingId = 0;

const removeToastsWithId = (toasts = [], id) => toasts.filter(toast => toast.id !== id);

export const addToast = store.action(
  ({ toasts = [] }, { id, type, message, persistent = false, timeout = 5000 }) => {
    id = id || `_INTERNAL_${(incrementingId += 1)}`;

    if (!persistent) {
      setTimeout(() => {
        const toasts = removeToastsWithId(store.getState().toasts, id);
        store.setState({ toasts });
      }, timeout);
    }

    // only allow a single toast for a given toast id
    toasts = removeToastsWithId(toasts, id);
    // add the toast to the end of the toast stack
    toasts = [...toasts, { id, type, message, persistent }];
    return { toasts };
  }
);

export const clearToast = store.action(({ toasts = [] }, id) => {
  toasts = removeToastsWithId(toasts, id);
  return { toasts };
});

export const clearAllToasts = store.action(() => ({ toasts: [] }));
