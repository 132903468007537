import { html, Component } from 'htm/preact';
import { createPortal } from 'preact/compat';

class Modal extends Component {
  constructor(props) {
    super(props);

    this.clickOutsideHandler = e => this.onClose(e);
  }

  componentDidMount() {
    if (this.props.show) {
      this.ensureClickOutsideModalHandler();
    }
  }

  componentDidUpdate(prevProps) {
    const { show, key } = this.props;
    const { prevShow, prevKey } = prevProps;

    if (show && (!prevShow || key !== prevKey)) {
      this.ensureClickOutsideModalHandler();
    }
  }

  ensureClickOutsideModalHandler() {
    document.removeEventListener('click', this.clickOutsideHandler);
    document.addEventListener('click', this.clickOutsideHandler);
  }

  onClose(e) {
    e.stopPropagation();
    if (this.props.variation !== Modal.VARIATION_NOT_DISMISSABLE) {
      this.props.onClose();
      document.removeEventListener('click', this.clickOutsideHandler);
    }
  }

  render({
    children,
    footer = null,

    portalMountId = 'app',
    key,
    confirmText,
    onConfirm = () => {},
    variation = Modal.VARIATION_CLOSE_WITH_BUTTON,
    closeText = 'Close',
    /* onClose gets wrapped */
    extraClassString,
  }) {
    if (!this.props.show) {
      return null;
    }

    const portalMountElement = document.getElementById(portalMountId);

    const onClose = this.onClose.bind(this);

    let hasButtons = variation === Modal.VARIATION_CLOSE_WITH_BUTTON || confirmText;

    return createPortal(
      html`
        <div onClick=${onClose} class="modal-background">
          <div onClick=${e => e.stopPropagation()} class="modal ${extraClassString}" key="${key}">
            ${variation === Modal.VARIATION_CLOSE_WITH_X &&
            html`
              <div class="modal-x" onClick=${onClose}>
                <svg viewBox="0 0 18 18">
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  />
                </svg>
              </div>
            `}
            ${children}
            ${hasButtons &&
            html`
              <div class="buttons">
                ${confirmText &&
                html`
                  <button class="listed-button" onClick=${onConfirm}>${confirmText}</button>
                `}
                ${variation === Modal.VARIATION_CLOSE_WITH_BUTTON &&
                html`
                  <button class="listed-button" onClick=${onClose}>${closeText}</button>
                `}
              </div>
            `}
            ${footer &&
            html`
              <div class="modal__footer">${footer}</div>
            `}
          </div>
        </div>
      `,
      portalMountElement
    );
  }
}

Modal.VARIATION_CLOSE_WITH_BUTTON = 'VARIATION_CLOSE_WITH_BUTTON';
Modal.VARIATION_CLOSE_WITH_X = 'VARIATION_CLOSE_WITH_X';
Modal.VARIATION_NOT_DISMISSABLE = 'VARIATION_NOT_DISMISSABLE';

export default Modal;
