import { html, Component } from 'htm/preact';
import { createRef } from 'preact';

import { ENVIRONMENT } from '../config';

import { formatCurrency, setCurrency } from '../utils/intl';
import {
  generateGuessEmoji,
  guessResult,
  CURRENT_GAME_CONTEXT,
  CURRENT_GAME_CONTEXT_ID,
  CURRENT_GAME_ID,
  IS_ARCHIVE_GAME,
} from '../utils/game';

import Listing from './Listing';
import GameOver from './GameOver';
import Modal from './Modal';
import Celebration from './Celebration';

import * as LocalStorage from '../localStorage';
import * as Tracking from '../tracking';

import { updateUserStats } from '../actions';

import { isOneYear } from '../milestones';

const parseGuess = guess => {
  const scrubbedGuess = guess.replace(/\D/g, '');
  const numericGuess = parseInt(scrubbedGuess, 10);
  if (isNaN(numericGuess)) {
    return null;
  }
  return numericGuess;
};

const INITIAL_CLIENT_STATE = {
  id: null,
  gameOver: false,
  guessed: false,
  guessHistory: [],
};

const INITIAL_SERVER_STATE = {
  listing: null,
  maxGuesses: null,
  salePrice: null,
};

const GuessHistory = ({ guessHistory, guessHasFocus, salePrice, inModal = false }) => html`
  <div class="listed-guess-history ${guessHasFocus ? 'listed-guess-history--focus' : ''}">
    ${guessHistory.map((guess, index) => {
      if (guessHasFocus || index === guessHistory.length - 1) {
        return html`
          <label>Guess ${index + 1}</label>
          <span>${formatCurrency(guess)}</span>
          ${!inModal &&
          html`
            <span></span>
            <label>${index === guessHistory.length - 1 ? 'Next Guess' : ''}</label>
          `}
          <span>${generateGuessEmoji(guess, salePrice)}</span>
        `;
      } else {
        return '';
      }
    })}
  </div>
`;

export default class Game extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serverState: INITIAL_SERVER_STATE,
      clientState: INITIAL_CLIENT_STATE,
      showingGuessHistory: false,
    };

    this.guessInputRef = createRef();
    this.keyCaptureFunc = null;
  }

  onGameOver(opts) {
    if (this.props.onGameOver) {
      this.props.onGameOver(opts);
    }
  }

  addGuess(guess) {
    const { id, gameOver, guessHistory } = this.state.clientState;
    const { salePrice, maxGuesses } = this.state.serverState;

    if (gameOver) {
      return;
    }

    const { win, computeType } = guessResult(guess, salePrice);
    const gameOverNow = win || guessHistory.length + 1 >= maxGuesses;

    let clientState = {
      id,
      gameOver: gameOverNow,
      guessed: win,
      guessHistory: [...guessHistory, guess],
    };
    this.setState({ clientState });

    this.syncClientStateToLocalStorage(clientState);

    Tracking.makeGuess({ id, guess, guessCount: guessHistory.length });

    if (gameOverNow) {
      if (!IS_ARCHIVE_GAME) {
        updateUserStats({ serverState: this.state.serverState, clientState });
      }

      this.onGameOver({ playSound: win });

      Tracking.finishGame({ id, guessCount: guessHistory.length, computeType });
      if (win) {
        Tracking.winGame({ id, guessCount: guessHistory.length, computeType });
      } else {
        Tracking.loseGame({ id, guessCount: guessHistory.length });
      }
    }
  }

  componentDidMount() {
    this.fetchDataFromServer();
    this.maybeSetupKeyCapture();
  }

  componentDidUpdate() {
    this.maybeSetupKeyCapture();
    this.maybeTeardownKeyCapture();
  }

  componentWillUnmount() {
    this.maybeTeardownKeyCapture();
  }

  maybeSetupKeyCapture() {
    if (this.guessInputRef.current && !this.keyCaptureFunc) {
      this.keyCaptureFunc = e => {
        if (parseInt(e.key, 10).toString() !== e.key) {
          return;
        }
        if (e.isComposing || e.altKey || e.ctrlKey || e.metaKey || e.shiftKey || e.repeat) {
          return;
        }
        if (document.activeElement !== this.guessInputRef.current) {
          this.guessInputRef.current.focus();
        }
      };
      window.addEventListener('keydown', this.keyCaptureFunc);
    }
  }

  maybeTeardownKeyCapture() {
    if (this.keyCaptureFunc && !this.guessInputRef.current) {
      window.removeEventListener('keydown', this.keyCaptureFunc);
      this.keyCaptureFunc = null;
    }
  }

  syncClientStateToLocalStorage({ gameOver, guessed, guessHistory, id }) {
    LocalStorage.updateClientState(CURRENT_GAME_CONTEXT, CURRENT_GAME_ID, {
      gameOver,
      guessed,
      guessHistory,
      id,
    });
  }

  onFocusGuess() {
    this.setState({ guessHasFocus: true });
  }
  onBlurGuess() {
    this.setState({ guessHasFocus: false });
  }

  onChangeGuess(e) {
    // e.target.value on first call will be a number, like 7
    // this will format the number to a currency string like $7
    // subsequent calls will then come in with a string like $72 or $721,7
    // and this code will reformat the value to $72 (unchanged) or $7,217
    // BUT doing so makes your cursor move, so we need to change the cursor position
    const guess = parseGuess(e.target.value);
    if (guess) {
      e.target.value = formatCurrency(guess);
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const value = formData.get('guess');
    const guess = parseGuess(value);

    if (guess) {
      this.addGuess(guess);
    } // else error handling

    form.elements.guess.value = '';
    form.elements.guess.blur();
  }

  async fetchDataFromServer() {
    const dataUrl = `/data/${CURRENT_GAME_CONTEXT_ID}/${CURRENT_GAME_ID}`;
    const response = await fetch(dataUrl);
    const listing = await response.json();
    const { id, currency } = listing;

    const clientStateFromLocalStorage = LocalStorage.getClientState(
      CURRENT_GAME_CONTEXT,
      CURRENT_GAME_ID
    );

    setCurrency(currency);

    const serverState = {
      listing,
      maxGuesses: listing.revealableAttributes.length + 1,
      salePrice: listing.salePrice,
    };

    if (this.props.onGameLoad) {
      this.props.onGameLoad(listing);
    }
    if (!clientStateFromLocalStorage || id !== clientStateFromLocalStorage.id) {
      // Start a new game by resetting clientState in addition to setting serverState
      const clientState = { ...INITIAL_CLIENT_STATE, id };
      this.syncClientStateToLocalStorage(clientState);
      this.setState({ serverState, clientState });
      Tracking.startGame({ id });
    } else {
      // Resume old game with this serverState; clientState is loaded from LocalStorage
      this.setState({ serverState, clientState: clientStateFromLocalStorage });
      if (clientStateFromLocalStorage.gameOver) {
        Tracking.returnPrematurely({ id });
        this.onGameOver();
      } else {
        Tracking.resumeGame({ id });
      }
    }
  }

  showGuessHistory(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showingGuessHistory: true });
  }

  render(_props, { clientState, serverState, guessHasFocus = false, showingGuessHistory = false }) {
    const { listing, salePrice, maxGuesses } = serverState;
    const { gameOver, guessHistory } = clientState;

    const guessCount = guessHistory.length;
    const remainingGuesses = maxGuesses - guessCount;

    const debug = ENVIRONMENT === 'development' && this.props.initQueryParams.debug === '1';

    return html`
      <div class="game">
        ${
          listing &&
          html`
            <${Listing}
              data=${listing}
              showCount=${guessCount + 1}
              showEverything=${gameOver || debug}
              showGuessHistory=${() => this.setState({ showingGuessHistory: true })}
            />
            ${!gameOver &&
            html`
              <div class="listed-guess-area">
                ${!gameOver &&
                html`
                  <${GuessHistory}
                    guessHistory=${guessHistory}
                    guessHasFocus=${guessHasFocus}
                    salePrice=${salePrice}
                  />
                  <form class="listed-guess-form" onSubmit=${e => this.onSubmit(e)}>
                    <input
                      name="guess"
                      class="listed-input"
                      type="tel"
                      placeholder=${(() => {
                        if (guessCount === 0) {
                          return 'Guess That Price';
                        } else if (remainingGuesses === 1) {
                          return 'Last Guess...';
                        } else {
                          return `${remainingGuesses} Guesses Left`;
                        }
                      })()}
                      onfocus=${e => this.onFocusGuess(e)}
                      onblur=${e => this.onBlurGuess(e)}
                      onInput=${e => this.onChangeGuess(e)}
                      ref=${this.guessInputRef}
                    />
                    ${' '}
                    <button class="listed-button" type="submit">Guess</button>
                  </form>
                `}
              </div>
            `}
            ${(debug || gameOver) &&
            html`
              <${GameOver}
                serverState=${serverState}
                clientState=${clientState}
                showGuessHistory=${e => this.showGuessHistory(e)}
              />
            `}
          `
        }
      </div>

      <${Celebration} active=${gameOver && isOneYear(clientState.id)} />

      <${Modal}
        show=${showingGuessHistory}
        key="modal--guess-history"
        onClose=${() => this.setState({ showingGuessHistory: false })}
        extraClassString="modal--guess-history"
      >
        <${GuessHistory}
          guessHistory=${guessHistory}
          guessHasFocus=${true}
          salePrice=${salePrice}
          inModal=${true}
        />
      </Modal>
    `;
  }
}
