import { ENVIRONMENT } from './config';

import { getUserId } from './user.js';
import { CURRENT_GAME_CONTEXT_ID, CURRENT_GAME_ID, IS_ARCHIVE_GAME } from './utils/game.js';

/*
For automatically collected events:
  https://support.google.com/analytics/answer/9234069

For sending events:
  https://support.google.com/analytics/answer/9267735
  https://developers.google.com/analytics/devguides/collection/ga4/reference/events

-- For all properties

Event                   Trigger when
ad_impression           a user sees an ad impression, for app only
earn_virtual_currency   a user earns virtual currency (coins, gems, tokens, etc.)
join_group              a user joins a group to measure the popularity of each group
login                   a user logs in
purchase                a user completes a purchase
refund                  a user receives a refund
search                  a user searches your content
select_content          a user selects content
share                   a user shares content
sign_up                 a user signs up to measure the popularity of each sign-up method
spend_virtual_currency  a user spends virtual currency (coins, gems, tokens, etc.)
tutorial_begin          a user begins a tutorial
tutorial_complete       a user completes a tutorial

-- For games

Event                   Trigger when
earn_virtual_currency	  a user earns virtual currency (coins, gems, tokens, etc.)
join_group              a user joins a group to measure the popularity of each group
level_end               a user completes a level in the game
level_start             a user starts a new level in the game
level_up                a user levels-up in the game
post_score              a user posts their score
select_content          a user selects content
spend_virtual_currency  a user spends virtual currency (coins, gems, tokens, etc.)
tutorial_begin          a user begins a tutorial
tutorial_complete       a user completes a tutorial
unlock_achievement      a user unlocks an achievement

*/

let warned = new Set();
const warnOnce = (service, e) => {
  if (!warned.has(service)) {
    console.warn(`${service} not loaded or disabled`, e);
    warned.add(service);
  }
};

const trackEvent = (eventName, params) => {
  const userId = getUserId();
  try {
    fetch('/e', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        e: eventName,
        u: userId,
        p: params,
      }),
    });
  } catch (e) {
    warnOnce('e', e);
  }

  if (ENVIRONMENT === 'development') {
    console.log(`TRACK event ${eventName} with params ${JSON.stringify(params)}`);
    return;
  }
};

// ---- event name

const currentOrArchiveEventName = baseEventName =>
  IS_ARCHIVE_GAME ? `archive:${baseEventName}` : baseEventName;

// ---- game play

const generateVisitorData = () => ({
  user_agent: navigator.userAgent || 'unknown',
  referrer: document.referrer || 'none',
  url: window.location.toString(),
  screen_resolution: `${screen.width}x${screen.height}`,
  window_resolution: `${window.innerWidth}x${window.innerHeight}`,
});

const globalGameData = () => ({
  game_context: CURRENT_GAME_CONTEXT_ID,
  game_id: CURRENT_GAME_ID,
  archive: IS_ARCHIVE_GAME,
});

export const visit = () =>
  trackEvent('visit', {
    ...globalGameData(),
    ...generateVisitorData(),
  });

let errorCount = 0;
const MAX_ERRORS = 1;

export const error = errorEvent => {
  if (errorCount > MAX_ERRORS) {
    return;
  }

  const errorObject = errorEvent.error;

  let source;
  if (errorObject.stack) {
    const splitStack = errorObject.stack.split('\n');
    if (splitStack.length > 2) {
      source = splitStack[1].trim();
    }
  }

  const eventData = {
    ...globalGameData(),
    ...generateVisitorData(),
    message: errorObject.message,
    source,
    file_name: errorEvent.filename || errorObject.fileName,
    line_number: errorEvent.lineno || errorObject.lineNumber,
    column_number: errorEvent.colno || errorObject.columnNumber,
  };

  trackEvent('error', eventData);

  errorCount += 1;
};

// ---- game play

// `start_game` (or `archive:start_game` if playing a game in the archive)
export const startGame = ({ id }) =>
  trackEvent(currentOrArchiveEventName('start_game'), {
    game_context: CURRENT_GAME_CONTEXT_ID,
    game_id: id,
  });

// `resume_game` (or `archive:resume_game` if playing a game in the archive)
export const resumeGame = ({ id }) =>
  trackEvent(currentOrArchiveEventName('resume_game'), {
    game_context: CURRENT_GAME_CONTEXT_ID,
    game_id: id,
  });

// `finish_game` (or `archive:finish_game` if playing a game in the archive)
export const finishGame = ({ id, guessCount, computeType }) =>
  trackEvent(currentOrArchiveEventName('finish_game'), {
    game_context: CURRENT_GAME_CONTEXT_ID,
    game_id: id,
    guess_count: guessCount,
    compute_type: computeType,
  });

// `win_game` (or `archive:win_game` if playing a game in the archive)
export const winGame = ({ id, guessCount, computeType }) =>
  trackEvent(currentOrArchiveEventName('win_game'), {
    game_context: CURRENT_GAME_CONTEXT_ID,
    game_id: id,
    guess_count: guessCount,
    compute_type: computeType,
  });

// `lose_game` (or `archive:lose_game` if playing a game in the archive)
export const loseGame = ({ id, guessCount }) =>
  trackEvent(currentOrArchiveEventName('lose_game'), {
    game_context: CURRENT_GAME_CONTEXT_ID,
    game_id: id,
    guess_count: guessCount,
  });

// `make_guess` (or `archive:make_guess` if playing a game in the archive)
export const makeGuess = ({ id, guess, guessCount }) =>
  trackEvent(currentOrArchiveEventName('make_guess'), {
    game_context: CURRENT_GAME_CONTEXT_ID,
    game_id: id,
    guess,
    guess_count: guessCount,
  });

// when you return to the app after finishing today's game, but the next game isn't ready yet
// `return_prematurely` (or `archive:return_prematurely` if playing a game in the archive)
export const returnPrematurely = ({ id }) =>
  trackEvent(currentOrArchiveEventName('return_prematurely'), {
    game_context: CURRENT_GAME_CONTEXT_ID,
    game_id: id,
  });

export const randomArchiveLink = () =>
  trackEvent(currentOrArchiveEventName('random_archive_link'), {
    game_context: CURRENT_GAME_CONTEXT_ID,
  });

// ---- modals

export const openHelp = () => trackEvent('open_help');

export const openAbout = () => trackEvent('open_about');

export const openStats = () => trackEvent('open_stats');

// ---- game-specific sharing

// `share` (or `archive:share` if playing a game in the archive)
export const share = ({ id, method }) =>
  trackEvent(currentOrArchiveEventName('share'), {
    game_context: CURRENT_GAME_CONTEXT_ID,
    game_id: id,
    item_id: id,
    method,
  });

// `share_abort` (or `archive:share_abort` if playing a game in the archive)
export const shareAbort = ({ id }) =>
  trackEvent(currentOrArchiveEventName('share_abort'), {
    game_context: CURRENT_GAME_CONTEXT_ID,
    game_id: id,
  });

// `share_fail` (or `archive:share_fail` if playing a game in the archive)
export const shareFail = ({ id }) =>
  trackEvent(currentOrArchiveEventName('share_fail'), {
    game_context: CURRENT_GAME_CONTEXT_ID,
    game_id: id,
  });

// ---- outbound links

export const clickBio = who => trackEvent('click_bio', { who });

export const clickKofi = () => trackEvent('click_kofi');

export const clickFeedback = () => trackEvent('click_feedback');

export const clickSocial = social => trackEvent('click_social', { social });

// ---- game-specific outbound links

// `click_listing` (or `archive:click_listing` if playing a game in the archive)
export const clickListing = ({ id }) =>
  trackEvent(currentOrArchiveEventName('click_listing'), {
    game_context: CURRENT_GAME_CONTEXT_ID,
    game_id: id,
  });
