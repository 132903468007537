import { html } from 'htm/preact';

import * as Tracking from '../tracking';

export default ({ children, ...other } = {}) => html`
  <a
    title="Contact"
    href="https://524i2at7vkt.typeform.com/to/Gl8JvbZE"
    target="_blank"
    ...${other}
    onClick=${() => Tracking.clickFeedback()}
  >
    ${children}
  </a>
`;
