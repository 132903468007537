function getItem(key) {
  let val;

  try {
    val = localStorage.getItem(key);
  } catch (e) {
    console.error(`could not read '${key}' from localStorage`, e);
  }

  if (val) {
    try {
      return JSON.parse(val);
    } catch (e) {
      console.error(`could not json parse localStorage '${key}' value '${val}'`, e);
    }
  }

  return null;
}

function setItem(key, val) {
  const valJson = JSON.stringify(val);

  try {
    localStorage.setItem(key, valJson);
  } catch (e) {
    console.error(`could not write '${key}' to localStorage`, e);
  }
}

export function clear() {
  localStorage.clear();
}

export function isFirstVisit() {
  const isFirstVisit = getItem('returningUser') !== true;
  setItem('returningUser', true);
  return isFirstVisit;
}

const oldClientStateKey = gameContext =>
  gameContext && gameContext.localStorageKeys
    ? gameContext.localStorageKeys.clientState
    : 'clientState';

const newClientStateKey = (gameContext, gameId) =>
  gameContext && gameContext.localStorageKeys
    ? `${gameContext.localStorageKeys.clientState}:${gameId}`
    : `clientState:${gameId}`;

export const updateClientState = (gameContext, gameId, gameState) => {
  setItem(newClientStateKey(gameContext, gameId), gameState);
};

export const getClientState = (gameContext, gameId) => {
  const clientState = getItem(newClientStateKey(gameContext, gameId));
  if (clientState) {
    return clientState;
  }
  return getItem(oldClientStateKey(gameContext));
};

const userStatsKey = gameContext =>
  gameContext && gameContext.localStorageKeys
    ? gameContext.localStorageKeys.userStats
    : 'userStats';

export const saveUserStats = (gameContext, userStats) => {
  setItem(userStatsKey(gameContext), userStats);
};

export const getUserStats = gameContext => getItem(userStatsKey(gameContext));

export function getUserId() {
  return getItem('userId');
}

export function setUserId(userId) {
  setItem('userId', userId);
}

export function hasSeenNewGameAd(newGameAdType) {
  return getItem(`seenNewGameAd:${newGameAdType}`) === true;
}

export function setHasSeenNewGameAd(newGameAdType) {
  setItem(`seenNewGameAd:${newGameAdType}`, true);
}
