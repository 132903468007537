import { html } from 'htm/preact';

export const HelpIcon = () => html`
  <svg class="icon icon--help" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <g fill="#000" fill-rule="evenodd">
      <path
        d="M16 2c7.732 0 14 6.268 14 14s-6.268 14-14 14S2 23.732 2 16 8.268 2 16 2Zm0 2.5C9.649 4.5 4.5 9.649 4.5 16S9.649 27.5 16 27.5 27.5 22.351 27.5 16 22.351 4.5 16 4.5Z"
        fill-rule="nonzero"
      />
      <circle cx="16" cy="23.5" r="2" />
      <path
        d="M16 6.75c3.19 0 5.75 2.56 5.75 5.75 0 2.587-1.656 4.476-4.198 5.37l-.302.099V20h-2.5v-4.025l1.005-.2c2.211-.443 3.495-1.628 3.495-3.275 0-1.81-1.44-3.25-3.25-3.25-1.136 0-2.224.687-3.292 2.198l-.168.245-2.08-1.386C12.006 7.988 13.863 6.75 16 6.75Z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
`;

export const StatsIcon = () =>
  html`
    <svg class="icon icon--stats" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fill-rule="evenodd">
        <path d="M19 48h22v65H19zM53 62h22v51H53zM87 21h22v92H87z" />
      </g>
    </svg>
  `;

export const HamburgerIcon = () =>
  html`
    <svg class="icon icon--hamburger" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fill-rule="evenodd">
        <path
          d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"
        />
      </g>
    </svg>
  `;

export const ContributeIcon = () =>
  html`
    <svg class="icon icon--contribute" viewBox="0 0 32 20" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000">
        <path
          d="M23.678.297a6.836 6.836 0 0 1 6.836 6.836v.402a6.836 6.836 0 0 1-6.836 6.836H21.72v2.057a3.155 3.155 0 0 1-3.155 3.155H3.668a3.155 3.155 0 0 1-3.154-3.155V1.875c0-.871.706-1.578 1.577-1.578h21.587Zm-9.52 4.286c-1.824 0-2.979.713-3.466 2.139-.487-1.426-1.642-2.139-3.465-2.139-2.734 0-3.747 3.401-2.314 5.625.955 1.482 2.881 3.357 5.78 5.625 2.897-2.268 4.823-4.143 5.779-5.625 1.433-2.224.42-5.625-2.315-5.625Zm7.562-.637v6.776h1.785a3.388 3.388 0 1 0 0-6.776H21.72Z"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  `;

export const ContactIcon = () =>
  html`
    <svg class="icon icon--contact" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <g fill="#000" fill-rule="nonzero">
        <path
          d="m27.25 8 1.5 2-12 9a1.25 1.25 0 0 1-1.386.076L15.25 19l-12-9 1.5-2L16 16.438 27.25 8Z"
        />
        <path d="M30 7v18H2V7h28Zm-2.5 2.5h-23v13h23v-13Z" />
        <path d="m12.233 15.013 1.534 1.974-9 7-1.534-1.974zM19.767 15.013l9 7-1.534 1.974-9-7z" />
        <path
          d="m27.25 8 1.5 2-12 9a1.25 1.25 0 0 1-1.386.076L15.25 19l-12-9 1.5-2L16 16.438 27.25 8Z"
        />
        <path d="M30 7v18H2V7h28Zm-2.5 2.5h-23v13h23v-13Z" />
        <path d="m12.233 15.013 1.534 1.974-9 7-1.534-1.974zM19.767 15.013l9 7-1.534 1.974-9-7z" />
      </g>
    </svg>
  `;

export const AudioOffIcon = () =>
  html`
    <svg class="icon icon--muted" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <g fill="#000" fill-rule="evenodd">
        <path
          d="m16 18.828 1 1V27h-2l-3.586-3.586L16 18.828Zm-7.414-7.414L13.172 16l-4.586 4.586L8 20H4v-8h4l.586-.586Zm11.3 3.528c.076.315.114.667.114 1.058 0 .39-.038.743-.115 1.058L18.828 16ZM17 5v7.172l-1 1-4.586-4.586L15 5h2Z"
        />
        <path
          d="m26.607 3.98 1.414 1.413L17.414 16l10.607 10.607-1.414 1.414L16 17.414 5.393 28.021 3.98 26.607 14.586 16 3.98 5.393 5.393 3.98 16 14.586 26.607 3.98Z"
        />
      </g>
    </svg>
  `;

export const AudioOnIcon = () =>
  html`
    <svg class="icon icon--unmuted" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <g fill="#000" fill-rule="evenodd">
        <path
          d="M4 12v8h4l7 7h2v-8c2 0 3-1 3-3s-1-3-3-3V5h-2l-7 7H4ZM23.767 4.35A13.987 13.987 0 0 1 30 16c0 4.859-2.475 9.14-6.234 11.65l-1.11-1.664A11.989 11.989 0 0 0 28 16c0-4.165-2.122-7.834-5.343-9.986Z"
        />
        <path
          d="M20.993 8.511A8.991 8.991 0 0 1 25 16a8.991 8.991 0 0 1-4.007 7.489l-1.109-1.665A6.993 6.993 0 0 0 23 16a6.993 6.993 0 0 0-3.116-5.824Z"
        />
      </g>
    </svg>
  `;

export const AboutIcon = () =>
  html`
    <svg class="icon icon--about" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <g fill="#000" fill-rule="evenodd">
        <path
          d="M16 2c7.732 0 14 6.268 14 14s-6.268 14-14 14S2 23.732 2 16 8.268 2 16 2Zm0 2.5C9.649 4.5 4.5 9.649 4.5 16S9.649 27.5 16 27.5 27.5 22.351 27.5 16 22.351 4.5 16 4.5Z"
          fill-rule="nonzero"
        />
        <path d="M13 25h6v-2h-1.5V13H13v2h1.5v8H13z" />
        <circle cx="16" cy="9" r="2" />
      </g>
    </svg>
  `;

export const Logo = () =>
  html`
    <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
      <defs>
        <clipPath id="a"><path d="M96 0v96H0V0h96Z" /></clipPath>
        <clipPath id="b">
          <path
            d="M.5 0C.776 0 1 1.343 1 3l-.005.424C.961 4.88.752 6 .5 6 .224 6 0 4.657 0 3l.005-.424C.039 1.12.248 0 .5 0Z"
          />
        </clipPath>
        <clipPath id="c">
          <path
            d="M.5 0C.776 0 1 1.343 1 3l-.005.424C.961 4.88.752 6 .5 6 .224 6 0 4.657 0 3l.005-.424C.039 1.12.248 0 .5 0Z"
          />
        </clipPath>
        <clipPath id="d">
          <path
            d="M2.5 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm0 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
          />
        </clipPath>
        <clipPath id="e">
          <path
            d="M2.5 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm0 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
          />
        </clipPath>
        <clipPath id="f"><path d="m15 0 5 3v8h68l3 5-3 5H20v84H10V21H0V11h10V3l5-3Z" /></clipPath>
        <clipPath id="g">
          <path
            d="M2.5 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm0 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
          />
        </clipPath>
        <clipPath id="h">
          <path
            d="M2.5 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm0 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
          />
        </clipPath>
        <clipPath id="i">
          <path
            d="M64 0v44H0V0h64ZM33.575 5.66h-2.569v3.553c-1.477.141-2.82.54-4.028 1.196a7.496 7.496 0 0 0-2.867 2.71c-.704 1.149-1.056 2.532-1.056 4.15 0 1.877.546 3.32 1.636 4.328 1.091 1.009 2.786 1.865 5.084 2.568.282.094.563.176.844.247.282.07.552.152.81.246v4.679a13.968 13.968 0 0 1-3.184-.844c-.997-.4-1.836-.798-2.516-1.197-.68-.398-1.079-.668-1.196-.809L22 31.518c1.243.821 2.656 1.495 4.24 2.023 1.582.528 3.172.862 4.766 1.003v4.116h2.569v-4.046a13.076 13.076 0 0 0 4.151-.932c1.29-.528 2.328-1.337 3.114-2.428.785-1.09 1.178-2.515 1.178-4.274 0-1.548-.317-2.797-.95-3.747-.633-.95-1.53-1.712-2.691-2.287-1.161-.574-2.539-1.085-4.134-1.53a5.846 5.846 0 0 0-.545-.176 29.926 29.926 0 0 1-.51-.14v-4.75c.867.117 1.712.34 2.533.668.82.329 1.507.657 2.058.985.551.329.873.551.967.669l2.533-4.785c-.961-.563-2.105-1.114-3.43-1.653-1.325-.54-2.75-.88-4.274-1.02V5.66Zm-.387 19.526c.797.281 1.436.598 1.917.95.48.351.721.82.721 1.407 0 1.149-.88 1.77-2.638 1.864v-4.221Zm-1.76-10.871v4.257c-.797-.258-1.395-.552-1.794-.88-.398-.328-.598-.774-.598-1.337 0-1.22.798-1.9 2.393-2.04Z"
          />
        </clipPath>
        <clipPath id="j">
          <path
            d="M11.575 0v3.553c1.524.141 2.949.481 4.274 1.02 1.325.54 2.469 1.091 3.43 1.654l-2.533 4.785c-.094-.118-.416-.34-.967-.669-.551-.328-1.237-.656-2.058-.985a10.628 10.628 0 0 0-2.533-.668v4.75c.164.046.334.093.51.14.176.047.357.106.545.176 1.595.445 2.973.956 4.134 1.53 1.16.575 2.058 1.337 2.691 2.287s.95 2.199.95 3.747c0 1.759-.393 3.184-1.178 4.274-.786 1.091-1.824 1.9-3.114 2.428-1.29.528-2.674.838-4.151.932V33H9.006v-4.116A20.824 20.824 0 0 1 4.24 27.88C2.656 27.353 1.243 26.68 0 25.858l2.533-5.03c.117.14.516.41 1.196.808.68.4 1.519.798 2.516 1.197.996.398 2.058.68 3.184.844v-4.68a8.152 8.152 0 0 0-.81-.245c-.281-.07-.562-.153-.844-.247-2.298-.703-3.993-1.56-5.084-2.568-1.09-1.008-1.636-2.45-1.636-4.327 0-1.619.352-3.002 1.056-4.152A7.496 7.496 0 0 1 4.978 4.75c1.208-.656 2.55-1.055 4.028-1.196V0h2.569Zm-.387 19.526v4.221c1.759-.093 2.638-.715 2.638-1.864 0-.587-.24-1.056-.721-1.408-.48-.351-1.12-.668-1.917-.95ZM9.428 8.655c-1.594.14-2.392.82-2.392 2.04 0 .563.2 1.009.598 1.337.399.328.997.622 1.795.88V8.655Z"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#a)">
        <g clip-path="url(#b)" transform="translate(32 22)"><path d="M0 0h1v6H0V0z" /></g>
        <g clip-path="url(#c)" transform="translate(83 22)"><path d="M0 0h1v6H0V0z" /></g>
        <g clip-path="url(#d)" transform="translate(81 19)"><path d="M0 0h5v5H0V0z" /></g>
        <g clip-path="url(#e)" transform="translate(30 19)"><path d="M0 0h5v5H0V0z" /></g>
        <g clip-path="url(#f)" transform="translate(2 1)"><path d="M0 0h91v95H0V0z" /></g>
        <g clip-path="url(#g)" transform="translate(30 26)"><path d="M0 0h5v5H0V0z" /></g>
        <g clip-path="url(#h)" transform="translate(81 26)"><path d="M0 0h5v5H0V0z" /></g>
        <g>
          <g clip-path="url(#i)" transform="translate(26 28)">
            <path class="logo__sign" fill="#AD2E41" d="M0 0h64v44H0V0z" />
          </g>
          <g clip-path="url(#j)" transform="translate(48 33.66)">
            <path class="logo__dollar" fill="#FFF" d="M0 0h20.018v33H0V0z" />
          </g>
        </g>
      </g>
    </svg>
  `;

export const RightArrow = () =>
  html`
    <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16">
      <path
        class="arrow__border"
        d="M2,2 L8,8 2,14"
        fill="transparent"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-width="4"
      />
      <path
        class="arrow__fill"
        d="M2,2 L8,8 2,14"
        fill="transparent"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-width="3"
      />
    </svg>
  `;

export const LeftArrow = () =>
  html`
    <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16">
      <path
        class="arrow__border"
        d="M8,2 L2,8 8,14"
        fill="transparent"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-width="4"
      />
      <path
        class="arrow__fill"
        d="M8,2 L2,8 8,14"
        fill="transparent"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-width="3"
      />
    </svg>
  `;
