import { html, Component } from 'htm/preact';
import { createRef } from 'preact';

export default class Celebration extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = createRef();
    this.animation = {};
    this.size = {};
    this.boundAnimate = this.animate.bind(this);
    this.count = 0;
  }

  componentDidMount() {
    if (this.props.active) {
      this.startAnimation();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.active && !prevProps.active) {
      this.startAnimation();
    }
  }

  startAnimation() {
    let canvas = this.canvasRef.current;
    if (!canvas) return;
    canvas.classList.remove('done');
    this.newFirework();
    requestAnimationFrame(this.boundAnimate);
  }

  newFirework() {
    this.count++;
    let canvas = this.canvasRef.current;
    if (!canvas) return;
    if (canvas.width !== canvas.offsetWidth) {
      canvas.width = canvas.offsetWidth;
    }
    if (canvas.height !== canvas.offsetHeight) {
      canvas.height = canvas.offsetHeight;
    }
    this.size = {
      width: canvas.offsetWidth,
      height: canvas.offsetHeight,
    };
    this.animation = {
      start: Date.now(),
      lastTick: Date.now(),
      pos: { x: this.size.width / 2, y: this.size.height },
      velocity: {
        x: (50 + Math.random() * 150) * (this.count % 2 ? -1 : 1),
        y: -750 - this.size.height / 2,
      },
      fuse: Math.random() * 300 + 1200,
      boomTime: Math.random() * 1000 + 1000,
      hue: Math.random() * 360,
      starCount: 100,
      stars: [],
      slugColor: window.getComputedStyle(canvas).getPropertyValue('--color-text') || '#000',
    };
    const scale = 75 + 50 * Math.random();
    for (let i = 0; i < this.animation.starCount; i++) {
      const angle = Math.random() * Math.PI * 2;
      const velocity = Math.random() * scale + scale;
      this.animation.stars.push({
        pos: {
          x: 0,
          y: 0,
        },
        velocity: {
          x: velocity * Math.cos(angle),
          y: velocity * Math.sin(angle),
        },
      });
    }
  }

  animate() {
    const canvas = this.canvasRef.current;
    if (!this.props.active || !canvas) return;
    const now = Date.now();
    const t = now - this.animation.start;
    const dt = now - this.animation.lastTick;
    const { hue, pos, velocity, fuse, starCount, stars, boomTime, slugColor } = this.animation;
    const ctx = canvas.getContext('2d');
    ctx.globalCompositeOperation = 'destination-out';
    ctx.fillStyle = 'rgba(255, 255, 255, .04)';
    ctx.fillRect(0, 0, this.size.width, this.size.height);
    ctx.globalCompositeOperation = 'source-over';
    if (t < fuse) {
      ctx.fillStyle = slugColor;
      pos.x = pos.x + velocity.x * (dt / 1000);
      pos.y = pos.y + velocity.y * (dt / 1000);
      velocity.y = velocity.y + 1000 * (dt / 1000);
      ctx.fillRect(pos.x - 1, pos.y - 1, 3, 3);
      if (pos.x < 0) {
        pos.x = 0;
        velocity.x = Math.abs(velocity.x);
      }
      if (pos.x > this.size.width) {
        pos.x = this.size.width;
        velocity.x = -Math.abs(velocity.x);
      }
    } else {
      ctx.fillStyle = `hsla(${hue}, 100%, 50%)`;
      for (let i = 0; i < starCount; i++) {
        const { pos: starPos, velocity: starVelocity } = stars[i];
        starPos.x += starVelocity.x * (dt / 1000);
        starPos.y += starVelocity.y * (dt / 1000);
        starVelocity.x *= 0.99;
        starVelocity.y = starVelocity.y + 50 * (dt / 1000);
        ctx.fillRect(pos.x + starPos.x, pos.y + starPos.y, 2, 2);
      }

      if (t > fuse + boomTime) {
        this.newFirework();
      }
    }
    this.animation.lastTick = now;
    if (this.count < 6) {
      requestAnimationFrame(this.boundAnimate);
    } else {
      this.endSequence();
    }
  }

  endSequence() {
    if (this.canvasRef.current) {
      this.canvasRef.current.classList.add('done');
    }
  }

  render() {
    return html`
      <canvas ref=${this.canvasRef} class="celebration"></canvas>
    `;
  }
}
