import { pacificTimeOffsetHours } from './intl.js';

import * as LocalStorage from '../localStorage';

const THRESHOLD_WIN_RELATIVE = 0.01;
const THRESHOLD_WIN_ABSOLUTE = 5000;
const THRESHOLD_CLOSE_RELATIVE = 0.1;
const THRESHOLD_CLOSE_ABSOLUTE = 10000;

export const COMPUTE_TYPE_RELATIVE = 'relative';
export const COMPUTE_TYPE_ABSOLUTE = 'absolute';

export const guessResult = (guess, answer) => {
  const absolute = Math.abs(guess - answer);
  const relative = absolute / answer;

  let win = false;
  let close = false;
  let computeType = null;
  if (relative <= THRESHOLD_WIN_RELATIVE) {
    win = true;
    computeType = COMPUTE_TYPE_RELATIVE;
  } else if (absolute <= THRESHOLD_WIN_ABSOLUTE) {
    win = true;
    computeType = COMPUTE_TYPE_ABSOLUTE;
  } else if (relative <= THRESHOLD_CLOSE_RELATIVE) {
    close = true;
    computeType = COMPUTE_TYPE_RELATIVE;
  } else if (absolute <= THRESHOLD_CLOSE_ABSOLUTE) {
    close = true;
    computeType = COMPUTE_TYPE_ABSOLUTE;
  }

  return { win, close, computeType };
};

export const generateGuessEmoji = (guess, answer) => {
  const { win, close } = guessResult(guess, answer);

  if (win) {
    return '🏡';
  } else if (close) {
    if (guess < answer) {
      return '↗️';
    } else {
      return '↘️';
    }
  } else if (guess < answer) {
    return '⬆️';
  } else if (guess > answer) {
    return '⬇️';
  }
};

export const dateStringToPstDate = dateString => {
  const rawDate = dateString ? new Date(dateString) : new Date();
  const offset = pacificTimeOffsetHours(rawDate);
  const pstDate = new Date();
  pstDate.setUTCFullYear(rawDate.getUTCFullYear(), rawDate.getUTCMonth(), rawDate.getUTCDate());
  pstDate.setUTCHours(-1 * offset, 0, 0, 0);
  return pstDate;
};

export const nowAsPstDate = () => dateStringToPstDate();

let tomorrowPacificTime;

export const getMillisUntilNextGame = () => {
  const now = new Date();

  if (!tomorrowPacificTime) {
    tomorrowPacificTime = new Date();
    // returns -7 when PDT or -8 when PST
    const tomorrowPacificTimeOffsetHours = pacificTimeOffsetHours(tomorrowPacificTime);
    tomorrowPacificTime.setUTCHours(-1 * tomorrowPacificTimeOffsetHours, 0, 0, 0);
    if (tomorrowPacificTime < now) {
      tomorrowPacificTime.setDate(tomorrowPacificTime.getDate() + 1);
    }
  }

  return Math.max(tomorrowPacificTime - now, 0);
};

const isGameContextActive = gameContextId => {
  const gameContext = ALL_GAME_CONTEXTS[gameContextId];
  return gameContext && nowAsPstDate() >= dateStringToPstDate(gameContext.startDate);
};

export const DEFAULT_GAME_CONTEXT_ID = ROLLUP_REPLACE__DEFAULT_GAME_CONTEXT_ID;
const ALL_GAME_CONTEXTS = ROLLUP_REPLACE__ALL_GAME_CONTEXTS;

export const ALL_ACTIVE_GAME_CONTEXTS = Object.keys(ALL_GAME_CONTEXTS)
  .filter(gameContextId => isGameContextActive(gameContextId))
  .reduce(
    (memo, gameContextId) => ({ ...memo, [gameContextId]: ALL_GAME_CONTEXTS[gameContextId] }),
    {}
  );

const NEWEST_AVAILABLE_GAME_IDS_BY_GAME_CONTEXT_ID = INJECTED__CURRENT_GAME_CONTEXT_IDS;
export const CURRENT_GAME_CONTEXT_ID = INJECTED__GAME_CONTEXT_ID;
export const CURRENT_GAME_CONTEXT = ALL_GAME_CONTEXTS[CURRENT_GAME_CONTEXT_ID];
export const CURRENT_GAME_ID = INJECTED__GAME_ID;
export const IS_ARCHIVE_GAME =
  CURRENT_GAME_ID < NEWEST_AVAILABLE_GAME_IDS_BY_GAME_CONTEXT_ID[CURRENT_GAME_CONTEXT_ID];

export const hasTodaysGameBeenPlayed = gameContext => {
  const todaysGameIdThisContext = NEWEST_AVAILABLE_GAME_IDS_BY_GAME_CONTEXT_ID[gameContext.id];
  const clientState = LocalStorage.getClientState(gameContext, todaysGameIdThisContext);
  return clientState && clientState.id === todaysGameIdThisContext && clientState.gameOver;
};

export const hasGameEverBeenPlayed = gameContext => {
  const userStats = LocalStorage.getUserStats(gameContext);
  return userStats && userStats.games && userStats.games.length > 0;
};
