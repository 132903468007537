import { html, Component } from 'htm/preact';

import { Provider } from 'unistore/full/preact.es.js';

import { ENVIRONMENT } from './config';

import store from './store';

import { loadUserStats } from './actions';

import * as LocalStorage from './localStorage';

import * as Tracking from './tracking';

import { GlobalEventBus } from './utils/events';
GlobalEventBus.addType('openMainMenu');

import MainMenu from './components/MainMenu';
import Game from './components/Game';
import AboutModal from './components/AboutModal';
import StatsModal from './components/StatsModal';
import HelpModal from './components/HelpModal';
import NewGameReadyModal from './components/NewGameReadyModal';
import ToastStack from './components/ToastStack';

import { HelpIcon, Logo } from './components/icons';

import {
  DEFAULT_GAME_CONTEXT_ID,
  ALL_ACTIVE_GAME_CONTEXTS,
  CURRENT_GAME_CONTEXT,
  CURRENT_GAME_CONTEXT_ID,
  CURRENT_GAME_ID,
  hasTodaysGameBeenPlayed,
  getMillisUntilNextGame,
} from './utils/game';

import Audio from './interfaces/audio';

import { isOneYear, isTwoYear } from './milestones';

const firstVisit = LocalStorage.isFirstVisit();

const clickOpenMainMenuCta = e => {
  GlobalEventBus.emit('openMainMenu');
  e.stopPropagation();
};

class App extends Component {
  constructor(props) {
    super(props);

    if (props.initQueryParams && props.initQueryParams.reset === '1') {
      LocalStorage.clear();
    }

    this.state = {
      activeModal: null,
      gameOver: false,
      newGameAdType: null,
      badge: {},
    };

    this.resizeDebounce = null;

    window.addEventListener('resize', () => {
      if (!this.resizeDebounce) {
        this.resizeDebounce = setTimeout(() => {
          this.resizeDebounce = null;
          this.setState({ height: window.innerHeight + 'px' });
        }, 100);
      }
    });

    const onInitialClick = () => {
      this.setupAudio();
      window.removeEventListener('click', onInitialClick);
    };
    window.addEventListener('click', onInitialClick);
  }

  async setupAudio() {
    if (!this.startedAudio) {
      this.startedAudio = true;
      Audio.init();
      const sound = await fetch(
        'https://listed-dot-fun.s3.us-west-1.amazonaws.com/assets/Cha-ching.mp3'
      );
      Audio.loadSounds({
        'cha-ching': sound,
      });
    }
  }

  componentDidMount() {
    this.setState({ height: window.innerHeight + 'px' });

    const debug = ENVIRONMENT === 'development' && this.props.initQueryParams.debug === '1';

    if (firstVisit && !debug) {
      this.openModal('help');
    }

    document.addEventListener('visibilitychange', () => this.checkForNewGame());
    setInterval(() => this.checkForNewGame(), 10 * 1000);

    Tracking.visit();
  }

  checkForNewGame() {
    if (getMillisUntilNextGame() === 0) {
      this.openModal('new-game-ready');
    }
  }

  openModal(activeModal, e) {
    this.setState({ activeModal });

    if (e) {
      e.preventDefault();
      e.stopPropagation();

      switch (activeModal) {
        case 'about':
          Tracking.openAbout();
          break;
        case 'stats':
          Tracking.openStats();
          break;
        case 'help':
          Tracking.openHelp();
          break;
      }
    }
  }

  closeModal() {
    this.setState({ activeModal: null });
  }

  onGameLoad(game) {
    if (game) {
      if (isOneYear(game.id)) {
        this.setState({
          badge: {
            content: '1 YR!',
            classSuffix: 'one-year',
          },
        });
      } else if (isTwoYear(game.id)) {
        this.setState({
          badge: {
            content: '2 YR!',
            classSuffix: 'one-year',
          },
        });
      }
    }
  }

  onGameOver({ playSound = false } = {}) {
    let newGameAdType = this.state.newGameAdType;
    if (CURRENT_GAME_ID > 385) {
      newGameAdType = 'archive-random';
    } else if (CURRENT_GAME_ID > 365) {
      newGameAdType = 'archive';
    } else if (
      CURRENT_GAME_CONTEXT_ID === DEFAULT_GAME_CONTEXT_ID &&
      ALL_ACTIVE_GAME_CONTEXTS['sf'] &&
      !hasTodaysGameBeenPlayed(ALL_ACTIVE_GAME_CONTEXTS['sf']) &&
      ALL_ACTIVE_GAME_CONTEXTS['chi'] &&
      !hasTodaysGameBeenPlayed(ALL_ACTIVE_GAME_CONTEXTS['chi'])
    ) {
      newGameAdType = 'contexts';
    }

    // Don't show this new game ad if the user has already dismissed it
    if (newGameAdType && LocalStorage.hasSeenNewGameAd(newGameAdType)) {
      newGameAdType = null;
    }

    this.setState({ gameOver: true, newGameAdType });

    if (playSound) {
      Audio.playSound('cha-ching');
    }
  }

  onOpenMainMenu() {
    if (this.state.newGameAdType) {
      LocalStorage.setHasSeenNewGameAd(this.state.newGameAdType);
    }

    this.setState({ newGameAdType: null });
  }

  render({ initQueryParams }, { activeModal, badge, gameOver, height = '100%', newGameAdType }) {
    let appClasses = 'app';
    if (gameOver) {
      appClasses += ' app--game-over';
    }
    if (newGameAdType) {
      appClasses += ' app--show-new-game-ad';
    }

    const badgeContent = badge.content || CURRENT_GAME_CONTEXT.badge;
    const badgeClassSuffix = badge.classSuffix || CURRENT_GAME_CONTEXT_ID;

    return html`
      <div id="app" class="${appClasses}" style=${`height:${height};`}>
        <header class="header">
          <${MainMenu}
            onOpen=${() => this.onOpenMainMenu()}
            openAbout=${e => this.openModal('about', e)}
            openStats=${e => this.openModal('stats', e)}
            openHelp=${e => this.openModal('help', e)}
          />
          <h1 class="listed-title">
            <div class="header__logo"><${Logo} /></div>
            Listed
            ${badgeContent &&
            html`
              <div class="listed-title__badge listed-title__badge--${badgeClassSuffix}">
                ${badgeContent}
              </div>
            `}
          </h1>
          <button class="header__button" onClick=${e => this.openModal('help', e)}>
            <${HelpIcon} />
          </button>

          ${newGameAdType &&
          html`
            <div class="new-game-ad">
              <span class="new-game-ad__message">
                ${newGameAdType === 'archive-random' &&
                html`
                  <div class="badge" onclick=${clickOpenMainMenuCta}>New menu options!</div>
                  Disable sound, contact us, or play games from the archive!
                `}
                ${newGameAdType === 'archive' &&
                html`
                  Celebrate 1 year of listed – play${' '}
                  <a href=${`/archive/${CURRENT_GAME_CONTEXT_ID}/${CURRENT_GAME_ID - 365}`}>
                    last year's game
                  </a>
                  !
                `}
                ${newGameAdType === 'contexts' &&
                html`
                  Wanna play more? Try Listed in ${' '}
                  <a href="/sf">San Francisco</a>
                  ${' '} or ${' '}
                  <a href="/chi">Chicago</a>
                  !
                `}
              </span>
            </div>
          `}
        </header>

        <${AboutModal} show=${activeModal === 'about'} onClose=${() => this.closeModal()} />

        <${StatsModal} show=${activeModal === 'stats'} onClose=${() => this.closeModal()} />

        <${HelpModal} show=${activeModal === 'help'} onClose=${() => this.closeModal()} />

        <${NewGameReadyModal} show=${activeModal === 'new-game-ready'} />

        <${Game}
          initQueryParams=${initQueryParams}
          onGameOver=${opts => this.onGameOver(opts)}
          onGameLoad=${game => this.onGameLoad(game)}
        />

        <${ToastStack} />
      </div>
    `;
  }
}

// TODO figure out if this is needed
loadUserStats();

export default class AppWithStore extends Component {
  render(props) {
    return html`
      <${Provider} store=${store}>
        <${App} ...${props} firstVisit=${firstVisit} />
      </Provider>
    `;
  }
}
